import React, { useState, useEffect } from 'react';
import C from '../config'

const address = {
  in: {
    street: "Rödingsmarkt 39",
    zip: 20459,
    city: "Hamburg",
    country: "Germany",
    legal: "Managing directors: Patrick Kollmann and Alexander von der Geest. Registered at Hamburg District Court, HRB 124705. The company's head office is situated in Hamburg. Gravite is an AddApptr GmbH brand.",
  },
  fr: {
    street: "92 Rue d'Aboukir",
    zip: 75002,
    city: "Paris",
    country: "France",
    legal: "Managing director: Alexander von der Geest. Registration with the Trade and Companies Register: 824485429 R.C.S.Paris. The company’s head office is situated in Hamburg. Gravite is an AddApptr GmbH brand.",
  }
}

const bannerList = [
  null,
  {img: 'https://i.imgur.com/V2Iq41H.png', href: 'https://gravite.net/'},
  {img: 'https://i.imgur.com/zrrPCK2.png', href: 'https://www.youtube.com/watch?v=LIm3hBW5hzc'},
]

const Template = ({ name, position, email, phone, country, banner }) => {
  if (!country) return '';
  return `
  <div style="max-width: 620px;background-color: transparent; ">
  <table style="color: #222222; border-collapse: collapse;font-family: Helvetica, Arial, sans-serif;" border="0">
    <tbody>
      <tr style="height: 97.0938px;">
        <td style="width: 200px; font-size: 12px;">
          <p style="font-family: Helvetica,Arial,sans-serif; font-size: medium;"><span style="font-size: 18px;">${name}</span> <span style="font-size: 14px;"><br />${position}</span></p>
        </td>
        <td style="padding-left: 5px; font-size: 12px; width: 175.5px;"><img
            src="https://ci3.googleusercontent.com/proxy/gEcEMY0J-PDOod2cI_MRMpVEsd1Zlrm0rmJ5rQ1M1WiVPsnV4Q5BJZuWY6V_lH5ppitodmVf6J02CyJhxS9NsQ=s0-d-e1-ft#https://i.postimg.cc/6pPC33Tv/logo-267x69.jpg"
            alt="gravite logo" width="120" height="31" /></td>
      </tr>
    </tbody>
    <tbody>
      <tr style="height: 46px;">
        <td style="width: 200px; font-size: 12px; "><a style="color: #1155CC;"
            href="mailto:${email}" target="_blank" rel="noopener">${email}</a> <br /> ${phone}
          <br /> <strong> <a style="color: #1155CC;" href="http://www.gravite.net/" target="_blank" rel="noopener"
              data-saferedirecturl="https://www.google.com/url?q=http://www.gravite.net/&amp;source=gmail&amp;ust=1681984351931000&amp;usg=AOvVaw1VwmUsqroJKFzWPPtq9bXG">www.gravite.net</a>
          </strong></td>
        <td style="width: 175.5px; padding-left: 5px; font-size: 12px; ">${address[country].street}<br />${address[country].zip}
          ${address[country].city}<br />${address[country].country}</td>
        <td style=" width: 50px;"><a href="https://www.linkedin.com/company/gravite-2023"
            target="_blank" rel="noopener"> <img
              src="https://ci6.googleusercontent.com/proxy/ZCnbuPgU2es7q866WWrLzqfmWsrm9KDpe4rNSYjV3nij4PQGfk5gCIwo2FkWIqucztPrZBwybldoQcP9-6_-2Nk2=s0-d-e1-ft#https://i.postimg.cc/VLWw9Gtf/linkedin-icon.png"
              alt="linkedin Logo" width="20" height="auto" /> </a></td>
      </tr>
    </tbody>
  </table>
  <br />
  <p style="font-size: 8px; font-family: Helvetica,Arial,sans-serif;">${address[country].legal}</p>
  ${C.emailBanner && banner && banner !== '0' ? `<div style="object-fit: contain;"><a href=${bannerList[banner].href} target="_blank"}>
    <img
      src=${bannerList[banner].img} height="auto" style="max-width: 600px;"/></div></a>` : ''}
</div>
`;
}

const SignagureForm = () => {
  const temp = Template('John', 'intern', 'john@gravite.net', '+123456789', 'in');
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('in');
  const [renderedSignature, setRenderedSignature] = useState(temp);
  const [banner, setBanner] = useState('');

  useEffect(() => {
    setRenderedSignature(Template({ name, position, email, phone, country, banner }))
  }, [name, position, email, phone, country, banner]);

  return (
    <div className="container" style={{ marginTop: '1rem' }}>
      Please fulfill the form to create a unique signature
      <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
      </div>
      <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Position" value={position} onChange={e => setPosition(e.target.value)} />
      </div>
      <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
      </div>
      <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
      </div>
      <div className="form-check">
        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={country == 'in'} onChange={e => setCountry('in')} />
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          International Template
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={country == 'fr'} onChange={e => setCountry('fr')} />
        <label className="form-check-label" htmlFor="flexRadioDefault2">
          French Template
        </label>
      </div>

      <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
        <label className="form-check-label" htmlFor="bannerSelect">
          Banner
        </label>
        <select className="form-select" aria-label="Default select example" id="bannerSelect" defaultValue='0' onChange={e => setBanner(e.target.value)}>
          <option value='0' >None</option>
          <option value="1">DMEXCO</option>
          <option value="2">Xmas</option>
        </select>
      </div>


      <div className="row" style={{ marginTop: "4rem" }}>
        Please Copy the following Signature and paste it on your email Client <i>for Apple Mail please be sure to UNCHECK "Always match my default message font"</i>
        <div className="shadow-lg p-3 mb-5 rounded" style={{ marginTop: "1rem" }} dangerouslySetInnerHTML={{ __html: renderedSignature }} />
      </div>
    </div >
  );
}

export default SignagureForm;
