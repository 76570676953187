import React, { useState, useEffect } from 'react';
import Signature from './Signature';
import Porfolio from './Portfolio';
import { GoogleLogin } from '@react-oauth/google';
import C from '../config';

const parseJwt = (cred) => {
    const { credential } = cred;
    try {
        const decodedCred = JSON.parse(atob(credential.split('.')[1]));
        // Store the data with time
        sessionStorage.setItem(C.sessionName, JSON.stringify({
            time: new Date().getTime(),
            cred: decodedCred
        }));
        return decodedCred;
    } catch (e) {
        return null;
    }
};

const Nav = (props) => {
    const { signatureSection, user, setUser } = props;
    return (
        <nav className="navbar bg-body-tertiary">
            <div className="container-fluid">
                <span className="navbar-brand mb-0 h1">
                    <a href="/">
                        <img src="https://i.postimg.cc/6pPC33Tv/logo-267x69.jpg" alt="gravite logo" width="120" height="31" />
                    </a>
                </span>
                <div style={{ float: 'right', display: 'inline-flex' }}>
                    {user?.given_name && <p style={{margin: '0.5rem'}}>Welcome back {user.given_name} <img style={{width:'1.5rem', borderRadius: '2rem'}} src={user?.picture} /></p>}
                    {!signatureSection && <GoogleLogin auto_select onSuccess={cred => setUser(parseJwt(cred))} type={user?.given_name ? `icon` : null} onError={(err) => console.log('Login Failed:', err)} />}
                </div>
            </div>
        </nav>
    );
}

const LoginCatch = (user) => user || isDev ? <Porfolio user={user?.email} /> : (<h3 style={{ textAlign: 'center', padding: '1%' }}>If you are gravite coworker, please log in.</h3>)

const App = () => {
    const [user, setUser] = useState(null);
    const isDev = process.env.NODE_ENV === 'development';
    const signatureSection = window.location.href.includes('#signature');

    useEffect(() => {
        const prevUser = JSON.parse(sessionStorage.getItem(C.sessionName));
        if (prevUser) {
            const hasExpired = (new Date().getTime() - prevUser?.time) > C.expireTime;
            if (prevUser.cred && !hasExpired) setUser(prevUser.cred);
        }
    }, []);

    return (
        <>
            <Nav setUser={(u) => { setUser(u) }} signatureSection={signatureSection} user={user} />
            {!signatureSection ? <LoginCatch user={user?.email} /> : null}
            {signatureSection ? <Signature /> : null}
        </>
    );
}

export default App
