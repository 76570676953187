import React, {useState} from 'react';
import { gTree } from '../config';

const Header = () => (
  <div className='team'>
    <div className="px-4 py-5 text-center cover">
      <h1 className="display-5 fw-bold text-body-emphasis text-top">Gravite Tools</h1>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">
          List of all the Gravite resources
        </p>
      </div>
    </div>
  </div>
)

const Projects = (user) => {
  const [efk, setEfk] = useState(null);
  return (
    gTree.map((l) => (
      <div className="container px-4 py-5" id="custom-cards" key={l.title}>
        <h2 className="pb-2 border-bottom">{l.title}</h2>
        <div className="row row-cols-1 row-cols-lg-4 align-items-stretch g-4 py-5">
          {l.children.map((child, index) => (
            <div className="col" key={child.url(user.user)}>
              <div
                onMouseEnter={() => setEfk(index)}
                onMouseLeave={() => setEfk(null)}
                className={`card card-cover overflow-hidden text-bg-dark rounded-4 shadow-lg ${efk === index ? 'zoomed' : ''}`}
                style={{ backgroundImage: `url(${child.img})`, backgroundSize: '120%', backgroundPosition: 'center', height: '230px', minWidth: '310px' }}
              >
                <a href={child.url(user.user)} target="_blank" className="btn btn-primary stretched-link" style={{ backgroundColor: child.img ? 'transparent' : 'blue', border: 'none', color: 'blueviolet', textShadow: '2px 2px 5px white' }}>
                  <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                    <h3 className="mt-5 mb-4 display-7 lh-1 fw-bold text-blue" style={{ background: '#ffffffab', color: 'black', border: '1px', borderRadius: '4px' }}>
                      {child.title}
                    </h3>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>)
    ))
}

const Porfolio = (props) => {
  return (
    <>
      <Header />
      <Projects user={props.user} />
    </>
  )
};

export default Porfolio
